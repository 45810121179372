<template>
  <div class="lesson"
       v-if="availableStatus !== 0"
        :class="{'lesson_wide' : isWide}">
    <svg
        v-if="availableStatus === 1"
        class="lesson__now-img"
        width="8px" height="9px" viewBox="0 0 8 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Главная-/1-блок/трансляция-расписание-и-запись/шалоны/3" transform="translate(-8.000000, -29.000000)" fill="#F54E5E" fill-rule="nonzero">
          <path d="M15.2111456,34.3944272 L9.4472136,37.2763932 C8.9532351,37.5233825 8.35256206,37.3231581 8.10557281,36.8291796 C8.03614509,36.6903242 8,36.5372111 8,36.381966 L8,30.618034 C8,30.0657492 8.44771525,29.618034 9,29.618034 C9.1552451,29.618034 9.30835816,29.6541791 9.4472136,29.7236068 L15.2111456,32.6055728 C15.7051241,32.8525621 15.9053485,33.4532351 15.6583592,33.9472136 C15.5615955,34.140741 15.404673,34.2976635 15.2111456,34.3944272 Z" id="Line-6"></path>
        </g>
      </g>
    </svg>
    <p class="lesson__name"
       :class="{'lesson__name_next' : availableStatus === 2}"
       >{{ schedule.name }}</p>
    <p class="lesson__time"
       :class="{'lesson__time_next' : availableStatus === 2,
        'lesson__time_wide' : isWide,
        'lesson__time_next_wide' : availableStatus === 2 && isWide}"
       v-if="schedule.name != 'Тихий час' && schedule.name != 'Перерыв'">
      {{time}}</p>
  </div>
  <div class="lesson lesson_archive"
       :class="{'lesson_wide' : isWide}"
       v-else>
    <p class="lesson_archive__name">{{ schedule.name }}</p>
    <div class="lesson_archive__buttons" :class="{'lesson_archive__buttons_wide' : isWide}"
      v-if="schedule.name != 'Тихий час' && schedule.name != 'Перерыв'">
      <p class="lesson_archive__time">{{begin}}-{{end}}</p>
      <RecordSvg
          @click.native="showButton"
          class="lesson_archive__archive"
          type="archive"/>
      <div
          class="lesson_archive__container"
          ref="archiveButton">
        <button
            class="lesson_archive__button"
            @click="$emit('showArchive',schedule)"
        >СМОТРЕТЬ ЗАПИСЬ</button>
      </div>
    </div>
  </div>
</template>

<script>
import RecordSvg from "../../assets/vue-icons/Settings/RecordSvg";
import anime from "animejs";
import moment from "moment/moment";
export default {
  name: "ArchiveLessonListItem",
  components: {RecordSvg},
  props:{
    schedule: Object,
    isWide:{
      default(){
        return false
      }
    }
  },
  data(){
    return {
      tl: null
    }
  },
  computed:{
    time(){
      if (this.availableStatus === 1){
        return 'идет занятие'
      } else {
        return `${this.begin}-${this.end}`
      }
    },
    begin(){
      if (this.schedule.begin != null){
        return moment(this.schedule.begin.date).format('HH:mm')
      } else{
        return moment(this.schedule._timeBegin.date).format('HH:mm')
      }
    },
    end(){
      if (this.schedule.end != null){
        return moment(this.schedule.end.date).format('HH:mm')
      } else{
        return moment(this.schedule._timeEnd.date).format('HH:mm')
      }
    },
    availableStatus(){
      if (this.schedule.availableStatus == null){
        return this.schedule._availableStatus
      } else {
        return this.schedule.availableStatus
      }
      // return this.schedule.availableStatus || this.schedule._availableStatus
    }
  },
  methods:{
    showButton(){
      this.$emit("show")
      if (this.tl != null){
        this.tl.pause()
      }
      this.tl = anime.timeline({
        easing: 'easeOutExpo',
        duration: 600
      });
      this.tl
          // .add({
          //   targets: this.$refs.archiveButton,
          //   opacity: 1,
          //   width: "26px"
          // })
          .add({
            targets: this.$refs.archiveButton,
            opacity: 1,
            width:"140px"
          })
    },


    hideButton(){
      if (this.tl != null){
        this.tl.pause()
      }
      this.tl = anime.timeline({
        easing: 'easeOutExpo',
        duration: 600
      });
      this.tl
          // .add({
          //   targets: this.$refs.archiveButton,
          //   opacity: 1,
          //   width: "26px"
          // })
          .add({
            targets: this.$refs.archiveButton,
            opacity: 0,
            width:"0px"
          })
    },
  }
}
</script>

<style scoped lang="scss">


.lesson{
  width: 658px;
  height: 59px;
  min-height: 59px;
  display: flex;
  border-bottom: 1px solid #F2F3F4;
  z-index: 5;



  @include media(767){
    width: 325px;
  }

  &_wide{
    width: 758px;
    @include media(1200){
      width: 658px;
    }

    @include media(767){
      width: 325px;
    }
  }

  &__name{
    font-family: Roboto, "sans-serif";
    width: 200px;
    margin-left: 8px;
    margin-top: 23px;

    font-size: 16px;
    color: #222222;
    letter-spacing: 0;
    line-height: 20px;

    &_next{
      font-family: Roboto, "sans-serif";
      font-size: 14px;
      color: #222222;
      letter-spacing: 0;
      line-height: 20px;
      margin-left: 8px;
      margin-top: 23px;
      width: 200px;
    }
  }

  &__time{
    width: 74px;
    font-family: Roboto, "sans-serif";
    font-size: 10px;
    color: #919191;
    letter-spacing: 0;
    text-align: right;
    line-height: 10px;
    margin-top: 29px;
    margin-left: 308px;

    @include media(767){
      margin-left: 16px;
    }

    &_wide{
      margin-left: 419px;

      @include media(1200){
        margin-left: 343px;
      }

      @include media(767){
        margin-left: 16px;
      }
    }




    &_next{
      margin-left: 354px;

      @include media(767){
        margin-left: 30px;
      }

      &_wide{
        margin-left: 435px;

        @include media(1200){
          margin-left: 360px;

        }

        @include media(767){
          margin-left: 30px;
        }
      }
    }
  }

  &__now-img{
    margin-top: 29px;
    margin-left: 8px;
  }


}


.lesson_archive{
  &__name{
    font-family: Roboto, "sans-serif";
    font-size: 14px;
    color: #909090;
    letter-spacing: 0;
    line-height: 20px;
    width: 200px;
    margin-left: 8px;
    margin-top: 23px;

    &_now{
      font-family: Roboto, "sans-serif";
      font-size: 16px;
      color: #222222;
      letter-spacing: 0;
      line-height: 20px;
      margin-left: 5px;
      margin-top: 23px;
      width: 200px;
    }

    &_next{
      font-family: Roboto, "sans-serif";
      font-size: 14px;
      color: #222222;
      letter-spacing: 0;
      line-height: 20px;
      margin-left: 8px;
      margin-top: 23px;
      width: 200px;
    }
  }

  &__time{
    width: 74px;
    font-family: Roboto, "sans-serif";
    font-size: 10px;
    color: #919191;
    letter-spacing: 0;
    text-align: right;
    line-height: 10px;
    position: absolute;
    left: 0;
    top: 12px;
  }


  &__buttons{
    margin-top: 17px;
    position: relative;
    min-width: 110px;
    max-width: 140px;
    margin-left: 324px;

    @include media(767){
      margin-left: 0;
    }

    &_wide{
      margin-left: 403px;

      @include media(1200){
        margin-left: 324px;
      }

      @include media(767){
        margin-left: 0;
      }
    }
  }

  &__button{
    background-image: linear-gradient(-42deg, #E83F94 0%, #F54E5E 100%);
    border-radius: 13px;
    min-width: 140px;
    width: 140px;
    height: 26px;
    font-family: Roboto, "sans-serif";
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 20px;
    border: 0;
    outline: 0;
    z-index: 3;
    clear: right;
    cursor: pointer;

    &:hover,&:active{
      filter: brightness(.9);
    }
  }

  &__archive{
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .lesson_archive__container{
    width: 0;
    overflow: hidden;
    right: 0;
    top: 0;
    z-index: 2;
    opacity: 0;
    position: absolute;
  }
}

*{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

}
</style>
