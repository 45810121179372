<template>
  <modal name="SelectArchiveModal" width="345px" height="auto" @before-open="onBeforeOpen">
    <div class="modal">
      <div v-for="camera of cameras"
           v-bind:key="camera.id"
           class="modal__item item"
           @click="onSelect(camera)">
        <RecordSvg
            class="item__icon"
            type="setting"/>
        <p class="item__text">{{camera.name}}</p>
      </div>
    </div>
  </modal>
</template>

<script>
import RecordSvg from "../../assets/vue-icons/Settings/RecordSvg";
export default {
  name: "SelectArchiveModal",
  components: {RecordSvg},
  data(){
    return {
      cameras: []
    }
  },
  methods:{
    onSelect(camera){
      this.$modal.hide("SelectArchiveModal")
      this.$emit("select",camera)
    },

    onBeforeOpen(e){
      this.cameras = [...e.params.cameras]
    }
  }
}
</script>

<style scoped lang="scss">
.modal{
  display: flex;
  overflow: scroll;
  width: 345px;
  height: auto;
  flex-direction: column;

  &__item{
    margin-top: 10px;
  }

  &__item:first-child{
    margin-top: 0;
  }
}

.item{
  background: #FFFFFF;
  border: 1px solid rgba(198,206,212,0.50);
  border-radius: 6px;
  width: 345px;
  height: 64px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &__icon{
    margin-left: 16px;
  }

  &__text{
    margin-left: 15px;
    font-family: Roboto, "sans-serif";
    font-weight: 500;
    font-size: 16px;
    color: #333942;
    text-align: left;
    width: 240px;
  }

  &:hover{
    background: darken(#ffffff,5%);
  }
}


*{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
