<template>
  <svg
      :class="[type]"
      v-if="type === 'archive'"
      width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Главная /1 блок/трансляция расписание и запись/инструменты/облачко</title>
    <defs>
      <linearGradient x1="100%" y1="72.7410835%" x2="0%" y2="30.46875%" id="linearGradient-1">
        <stop stop-color="#E83F94" offset="0%"></stop>
        <stop stop-color="#F54E5E" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Главная-/1-блок/трансляция-расписание-и-запись/инструменты/облачко" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M11.7142857,21 L5.64785714,21 C3.085,21 1,18.9729167 1,16.4808333 C1,14.75 2.15928571,12.9533333 3.757,12.20875 L4,12.0958333 L4,11.8333333 C4,11.7804167 4.00342857,11.7266667 4.00642857,11.6725 L4.01028571,11.6058333 L4.00514286,11.5183333 C4.00257143,11.4845833 4,11.4508333 4,11.4166667 C4,9.80875 5.34614286,8.5 7,8.5 C7.13242857,8.5 7.26314286,8.51125 7.393,8.52791667 C7.42642857,8.53208333 7.45985714,8.5375 7.49285714,8.54291667 C7.60728571,8.56125 7.72,8.58541667 7.831,8.61666667 C7.846,8.62083333 7.86142857,8.62375 7.87642857,8.62791667 C7.99857143,8.66416667 8.11771429,8.71 8.23428571,8.76166667 C8.26471429,8.775 8.29428571,8.78958333 8.32428571,8.80375 C8.42714286,8.85333333 8.52785714,8.9075 8.62514286,8.96875 C9.451,9.48875 10,10.3908333 10,11.4166667 C10,11.6470833 10.1915714,11.8333333 10.4285714,11.8333333 C10.6655714,11.8333333 10.8571429,11.6470833 10.8571429,11.4166667 C10.8571429,10.2691667 10.3231429,9.24208333 9.48571429,8.55375 C10.7144286,6.99125 12.7377143,6 14.7507143,6 C18.07,6 20.8265714,8.55625 21.1141429,11.7866667 C20.6761429,11.7566667 20.0204286,11.74125 19.3642857,11.8383333 C19.1302857,11.8729167 18.9695714,12.0854167 19.0051429,12.3129167 C19.0372857,12.5191667 19.2198571,12.6670833 19.4281429,12.6670833 C19.4495714,12.6670833 19.4714286,12.6654167 19.4928571,12.6620833 C20.4472857,12.5216667 21.442,12.6533333 21.5002857,12.66125 C23.4957143,13.03 25,14.8170833 25,16.8216667 C25,19.1254167 23.0718571,21 20.7022857,21 L15.1428571,21 L11.7142857,21 Z" id="Path" fill="url(#linearGradient-1)"></path>
    </g>
  </svg>
  <svg v-else-if="type === 'setting'"
       :class="[type]"
       width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Профиль/инструменты/запись</title>
    <g id="Профиль/инструменты/запись" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path d="M14.9285714,22 L9.87321429,22 C7.7375,22 6,20.2431944 6,18.0833889 C6,16.5833333 6.96607143,15.0262222 8.2975,14.3809167 L8.5,14.2830556 L8.5,14.0555556 C8.5,14.0096944 8.50285714,13.9631111 8.50535714,13.9161667 L8.50857143,13.8583889 L8.50428571,13.7825556 C8.50214286,13.7533056 8.5,13.7240556 8.5,13.6944444 C8.5,12.3009167 9.62178571,11.1666667 11,11.1666667 C11.1103571,11.1666667 11.2192857,11.1764167 11.3275,11.1908611 C11.3553571,11.1944722 11.3832143,11.1991667 11.4107143,11.2038611 C11.5060714,11.21975 11.6,11.2406944 11.6925,11.2677778 C11.705,11.2713889 11.7178571,11.2739167 11.7303571,11.2775278 C11.8321429,11.3089444 11.9314286,11.3486667 12.0285714,11.3934444 C12.0539286,11.405 12.0785714,11.4176389 12.1035714,11.4299167 C12.1892857,11.4728889 12.2732143,11.5198333 12.3542857,11.5729167 C13.0425,12.0235833 13.5,12.8053889 13.5,13.6944444 C13.5,13.8941389 13.6596429,14.0555556 13.8571429,14.0555556 C14.0546429,14.0555556 14.2142857,13.8941389 14.2142857,13.6944444 C14.2142857,12.6999444 13.7692857,11.8098056 13.0714286,11.21325 C14.0953571,9.85908333 15.7814286,9 17.4589286,9 C20.225,9 22.5221429,11.2154167 22.7617857,14.0151111 C22.3967857,13.9891111 21.8503571,13.97575 21.3035714,14.0598889 C21.1085714,14.0898611 20.9746429,14.2740278 21.0042857,14.4711944 C21.0310714,14.6499444 21.1832143,14.7781389 21.3567857,14.7781389 C21.3746429,14.7781389 21.3928571,14.7766944 21.4107143,14.7738056 C22.2060714,14.6521111 23.035,14.7662222 23.0835714,14.7730833 C24.7464286,15.0926667 26,16.6414722 26,18.3787778 C26,20.3753611 24.3932143,22 22.4185714,22 L17.7857143,22 L14.9285714,22 Z" id="Path" fill="#788396"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "RecordSvg",
  props:{
    type: String
  }
}
</script>

<style scoped lang="scss">
.setting.selected{
  g{
    path{
      fill: #333942;
    }
  }

  &:hover{
    g{
      path{
        fill: #333942;
      }
    }
  }
}

.setting:hover{
  g{
    path{
      fill: #6E7889;
    }
  }
}

svg{
  cursor: pointer;
}


.setting.disabled{
  opacity: .5;
  pointer-events: none;
}
</style>
